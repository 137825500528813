<template>
    <!-- <div v-html="$t('ChannelLang.html')"></div> -->
    <div class="container-pc">
        <div class="channel-view">
            <div class="channel-title-view">
                <div class="channel-title">Sales Platforms</div>
                <div class="channel-desc">Mainland China</div>
            </div>
            <div class="channel-item-view">
                <a class="channel-item" v-for="(item, index) in ChannelData.one" :key="index" :href="item.link"
                    target="_blank‌">
                    <img class="IMGHOVER" :src="item.imgUrl" alt="">
                </a>
            </div>

            <div class="channel-title-view">
                <div class="channel-desc">International</div>
            </div>
            <div class="channel-item-view">
                <a class="channel-item" v-for="(item, index) in ChannelData.two" :key="index" :href="item.link"
                    target="_blank‌">
                    <img class="IMGHOVER" :src="item.imgUrl" alt="">
                </a>
            </div>

            <div class="channel-title-view">
                <div class="channel-title">Social Media</div>
                <div class="channel-desc">Mainland China</div>
            </div>
            <div class="channel-item-view">
                <a class="channel-item" v-for="(item, index) in ChannelData.three" :key="index" :href="item.link"
                    target="_blank‌">
                    <img class="IMGHOVER" :src="item.imgUrl" alt="">
                </a>
            </div>

            <div class="channel-title-view">
                <div class="channel-desc">International</div>
            </div>
            <div class="channel-item-view">
                <a class="channel-item" v-for="(item, index) in ChannelData.fourth" :key="index" :href="item.link"
                    target="_blank‌">
                    <img class="IMGHOVER" :src="item.imgUrl" alt="">
                </a>
            </div>
        </div>

    </div>


    <div class="container-m">

        <div class="channel-m-view">
            <div class="channel-title-view">
                <div class="channel-title">Sales Platforms</div>
                <div class="channel-desc">Mainland China</div>
            </div>
            <div class="channel-m-item-view">
                <a class="channel-item" v-for="(item, index) in ChannelData.one" :key="index" :href="item.link"
                    target="_blank‌">
                    <img :src="item.imgUrl" alt="">
                </a>
            </div>

            <div class="channel-title-view">
                <!-- <div class="channel-title"></div> -->
                <div class="channel-desc">International</div>
            </div>
            <div class="channel-m-item-view">
                <a class="channel-item" v-for="(item, index) in ChannelData.two" :key="index" :href="item.link"
                    target="_blank‌">
                    <img :src="item.imgUrl" alt="">
                </a>
            </div>


            <div class="channel-title-view">
                <div class="channel-title">Social Media</div>
                <div class="channel-desc">Mainland China</div>
            </div>
            <div class="channel-m-item-view">
                <a class="channel-item" v-for="(item, index) in ChannelData.three" :key="index" :href="item.link"
                    target="_blank‌">
                    <img :src="item.imgUrl" alt="">
                </a>
            </div>


            <div class="channel-title-view">
                <!-- <div class="channel-title"></div> -->
                <div class="channel-desc">International</div>
            </div>
            <div class="channel-m-item-view">
                <a class="channel-item" v-for="(item, index) in ChannelData.fourth" :key="index" :href="item.link"
                    target="_blank‌">
                    <img :src="item.imgUrl" alt="">
                </a>
            </div>


        </div>
    </div>

</template>




<script name="Channel" setup>
import { onMounted, ref } from 'vue'

const ChannelData = ref({
    one: [{
        imgUrl: 'https://www.roseonly.com/icon/gw.png',
        link: 'https://www.roseonly.com.cn/'
    },
    {
        imgUrl: 'https://www.roseonly.com/icon/tm.png',
        link: 'https://roseonly.tmall.com/'
    },
    {
        imgUrl: 'https://www.roseonly.com/icon/jd.png',
        link: 'https://mall.jd.com/index-1000014661.html'
    },
    {
        imgUrl: 'https://www.roseonly.com/icon/douyin.png',
        link: 'https://www.douyin.com/user/MS4wLjABAAAANLUfKHzBVcNOyvZmETahlKc1RXh6Zc8u_5-Hm_nXsSc'
    },
    {
        imgUrl: 'https://www.roseonly.com/icon/dewu.png',
        link: 'https://m.dewu.com/h5-sociality/community/user-home-page/hybird/h5other/shareMiddle'
    }
    ],
    two: [{
        imgUrl: 'https://www.roseonly.com/icon/ymx-c.png',
        link: 'https://www.amazon.com/stores/page/919F8B9F-6C5E-4957-9A9E-D202D340195B'
    },
    {
        imgUrl: 'https://www.roseonly.com/icon/ymx-jp.png',
        link: 'https://www.amazon.co.jp/stores/page/ECFB68AC-48B2-4DED-BEC7-B196A39A3600'
    },
    {
        imgUrl: 'https://www.roseonly.com/icon/Shoppee-t.png',
        link: 'https://xiapi.xiapibuy.com/roseonlyofficial'
    },
    {
        imgUrl: 'https://www.roseonly.com/icon/Shopee.png',
        link: 'https://sg.xiapibuy.com/roseonlyofficial.sg'
    },
    {
        imgUrl: 'https://www.roseonly.com/icon/Lazada.png',
        link: 'https://www.lazada.sg/shop/roseonly/'
    },
    ],
    three: [{
        imgUrl: 'https://www.roseonly.com/icon/wb.png',
        link: 'https://weibo.com/u/3206773285'
    },
    {
        imgUrl: 'https://www.roseonly.com/icon/wx-xcx.png',
        link: ''
    },
    {
        imgUrl: 'https://www.roseonly.com/icon/xhs.png',
        link: 'https://www.xiaohongshu.com/user/profile/5ce78c30000000001101436f'
    }, {
        imgUrl: 'https://www.roseonly.com/icon/douyin.png',
        link: 'https://www.douyin.com/user/MS4wLjABAAAANLUfKHzBVcNOyvZmETahlKc1RXh6Zc8u_5-Hm_nXsSc'
    }],
    fourth: [{
        imgUrl: 'https://www.roseonly.com/icon/Instagram.png',
        link: 'https://www.instagram.com/roseonlyofficial/'
    },
    {
        imgUrl: 'https://www.roseonly.com/icon/Facebook.png',
        link: 'https://www.facebook.com/roseonlyofficial'
    },
    {
        imgUrl: 'https://www.roseonly.com/icon/Threads.png',
        link: 'https://www.threads.net/@roseonlyofficial'
    },
    {
        imgUrl: 'https://www.roseonly.com/icon/Tiktok.png',
        link: 'https://www.tiktok.com/@roseonlyofficial'
    },
    {
        imgUrl: 'https://www.roseonly.com/icon/twitter.png',
        link: 'https://x.com/roseonlyworld'
    }]
})

onMounted(() => {
    scrollTop()
})
const scrollTop = () => {
    window.scrollTo(0, 0); // 将页面滚动到顶部
}

</script>

<style lang="scss" scoped>
.channel-view {
    width: 100%;

    .channel-title {
        width: 100%;
        font-size: 20px;
        line-height: 40px;
        height: 40px;
        text-align: center;
        margin-top: 20px;

    }

    .channel-desc {
        width: 100%;
        font-size: 12px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: #3d3d3d;
        border-bottom: 1px solid #e1e1e3;
    }
}

.channel-item-view {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 50px 0;

    .channel-item {
        display: inline-block;
        width: 15%;
        // height: 235px;
        background-color: #f4f5f6;
        padding: 0 20px;
        margin: 5px;
        cursor: pointer;
    }
}



.channel-m-view {
    font-size: .30rem;

    .channel-title-view {
        widows: 100%;
        text-align: center;

        .channel-title {
            height: 40px;
            line-height: 40px;
            font-size: .35rem;
            margin-top: .2rem;
        }

        .channel-desc {
            height: .6rem;
            line-height: .6rem;
            font-size: .25rem;
            color: #3d3d3d;
            border-bottom: 1px solid #e1e1e3;
        }
    }


    .channel-m-item-view {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .channel-item {
            width: 30%;
            background: #f4f5f6;
            margin: 5px;
        }
    }


}
</style>